
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NormalButton',
  props: {
    text: { type: String },
    dropdown: { type: Boolean, default: false },
    parentFunction: { type: Function, default: () => null }
  }
})
