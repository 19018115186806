import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo.png'


const _hoisted_1 = {
  align: "center",
  "font-family": "Bell MT, sans-serif"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_navigation_drawer, {
      modelValue: _ctx.drawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawer) = $event)),
      class: _normalizeClass(_ctx.smallScreen ? 'h-75' : 'py-10'),
      location: _ctx.smallScreen ? 'bottom' : 'right',
      temporary: "",
      touchless: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_list, {
          density: "compact",
          nav: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, {
              "prepend-icon": "mdi-home-account",
              title: "Home",
              to: "/",
              value: "dashboard"
            }),
            _createVNode(_component_v_list_item, {
              title: "Pralines",
              to: "/pralines/"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_divider)
      ]),
      _: 1
    }, 8, ["modelValue", "class", "location"]),
    _createVNode(_component_v_app_bar, { color: "background" }, {
      prepend: _withCtx(() => [
        _createVNode(_component_v_app_bar_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_img, {
              height: "75px",
              src: _imports_0,
              width: "100px",
              onClick: this.goHome
            }, null, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      title: _withCtx(() => [
        _createVNode(_component_v_app_bar_title, null, {
          default: _withCtx(() => [
            _createElementVNode("h3", _hoisted_1, [
              _createTextVNode(" Vanaf vrijdag 6 september zijn er terug verse pralines! "),
              _createVNode(_component_svg_icon, {
                type: "mdi",
                path: _ctx.heart
              }, null, 8, ["path"])
            ])
          ]),
          _: 1
        })
      ]),
      append: _withCtx(() => [
        _createVNode(_component_v_app_bar_nav_icon, {
          color: "secondary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.drawer = !_ctx.drawer))
        })
      ]),
      _: 1
    })
  ], 64))
}