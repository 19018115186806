import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.parentFunction())),
    rounded: "pill",
    color: "primary"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["text-center text-secondary", [{'text-h6': /[a-z0-9]/i.test(_ctx.text)}, 'text-h5']])
      }, [
        _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
      ], 2),
      (this.dropdown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_v_icon, {
              right: "",
              color: "secondary"
            }, {
              default: _withCtx(() => [
                _createTextVNode("mdi-menu-down")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}