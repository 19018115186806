import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_NavigationBar = _resolveComponent("NavigationBar")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      _createVNode(_component_NavigationBar),
      _createVNode(_component_v_divider, {
        thickness: 1,
        class: "border-opacity-100",
        color: "surface"
      }),
      _createVNode(_component_Footer)
    ]),
    _: 1
  }))
}