<template>
  <v-container class="container-border" style="padding: 15px" align="center">
    <v-row align="center" class="d-flex flex-column">
      <v-card-item class="d-flex flex-fill">
        <v-col class="justify-end">
          <v-img :src="require(`@/assets/${this.praline.image}`)" width="200px" />
        </v-col>
        <!--
        <v-col>
          <p data-test="naam" class="title">{{ this.praline.naam }}</p>
        </v-col>
        -->
        <v-col>
          <p data-test="beschrijving" class="beschrijving">{{this.praline.beschrijving}}</p>
        </v-col>
        <v-col>
          <p data-test="allergeen" class="allergeen">{{this.praline.allergeen}}</p>
        </v-col>

        <v-col v-if="this.isAdminOrSu" align-self="center" cols="12">
          <v-btn  data-test="delete" icon tile style="max-height: 35px; max-width: 35px;" v-on:click="deletePraline">
            <DeleteIcon />
          </v-btn>
        </v-col>
<!--        <v-col cols="12">-->
<!--          <div v-if="!this.showIngredients" @click="this.showIngredients = true" class="ingredientenknop">Toon ingredienten</div>-->
<!--          <div v-else>-->
<!--            <p style="font-size: 18px">{{this.praline.ingredienten}}</p>-->
<!--            <p class="ingredientenknop" @click="this.showIngredients=false">Verberg ingredienten</p>-->
<!--          </div>-->
<!--        </v-col>-->

      </v-card-item>
    </v-row>
  </v-container>
</template>

<script>
import EditIcon from '@/components/icons/EditIcon.vue'
import DeleteIcon from "@/components/icons/DeleteIcon.vue";

/**
 * BuildingCard component wordt gebruikt door als props een Object met de volgende keys mee te geven:
 * gebouw: String
 * adres: String
 * status: String
 * efficiency: Number
 */

export default {
  name: 'PralineCard',
  components: {DeleteIcon, EditIcon},
  props: {
    praline: {
      type: Object,
      default: {
        id: 0,
        naam:'',
        beschrijving: '',
        ingredienten: '',
        image: '',
        doos: 0
      }
    }
  },
  data : () => ({
    showIngredients: false,
    isAdminOrSu: false,
  }),
  async beforeCreate() {
    this.isAdminOrSu = false;
  },
}

</script>

<style scoped>
.title {
  font-size: 20px;
  font-family: "Bell MT", sans-serif;
}
.beschrijving{
  font-size: 15px;
  font-family: "Bell MT", sans-serif;
}
.allergeen{
  font-weight: bold;
  font-family: "Bell MT", sans-serif;
}
.ingredientenknop{
  text-decoration-line: underline;
  cursor: pointer;
  font-size: 18px;
  color: #0645AD;
}

</style>
